import React, { useEffect, useState } from "react"
import { Container, Spinner } from "react-bootstrap"
import Header from "../components/Header"
import Projects from "../components/Projects"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import "../styles/global.scss"
import { init } from "@emailjs/browser"
import SEO from "../components/Seo"

export default function Home({ data }) {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    init("user_Id9vq0bbHAUDOGyFVvORh")
    if (window) {
      if (!localStorage.getItem("initLoading")) {
        setLoading(true)
        const loader = setTimeout(() => {
          setLoading(false)
          localStorage.setItem("initLoading", true)
        }, 5000)
      }
    }
  }, [])

  return loading ? (
    <div
      style={{
        width: "100%",
        height: "100vh",
        backgroundColor: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "4rem",
        fontFamily: "HelveNeueMedium",
        fontWeight: 700,
      }}
    >
      torus
      <Spinner animation="grow" role="status" variant="light" className="my-2">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <>
      <SEO title="Home" />
      <Header hiddenVideo={false} />
      <Container fluid>
        <Projects />
        <Contact />
      </Container>
      <Footer />
    </>
  )
}
