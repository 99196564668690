import { Formik, Field, Form } from "formik"
import React, { useRef, useState } from "react"
import { Container, Spinner } from "react-bootstrap"
import * as styles from "../styles/contact.module.scss"
import TitlesSection from "./TitlesSection"
import { Fade } from "react-reveal"
import emailjs from "@emailjs/browser"
import Swal from "sweetalert2"
import ReCAPTCHA from "react-google-recaptcha"

export default function Contact() {
  const [loading, setLoading] = useState(false)
  const SERVICE_ID = "service_7mc7e9w"
  const TEMPLATE_ID = "template_kfeju5a"
  const RECAPTCHA_KEY = "6LeA4TseAAAAALlWnHdLzzE2tZSbtvFUFs3jskVF"
  const recaptchaRef = useRef()

  return (
    <Container
      fluid
      style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "white" }}
      id="contact"
    >
      <Fade bottom>
        <TitlesSection title="contacto" />
      </Fade>
      <Formik
        initialValues={{
          name: "",
          email: "",
          message: "",
        }}
        onSubmit={async (values, actions) => {
          setLoading(true)
          const params = {
            to_name: "Torus Cine",
            from_name: values.name,
            from_email: values.email,
            message: values.message,
          }
          try {
            const tokenReCaptcha = await recaptchaRef.current.executeAsync()
            if (tokenReCaptcha) {
              const resp = await emailjs.send(SERVICE_ID, TEMPLATE_ID, params)
              if (resp.status === 200) {
                Swal.fire("Mensaje Enviado", "Muchas gracias", "success")
              } else {
                Swal.fire(
                  "Intente nuevamente mas tarde",
                  "Muchas gracias",
                  "error"
                )
              }
            } else {
              Swal.fire(
                "Intente nuevamente mas tarde",
                "Muchas gracias",
                "error"
              )
            }

            setLoading(false)
            actions.setSubmitting(false)
            actions.resetForm()
          } catch (error) {
            setLoading(false)
            Swal.fire("Intente nuevamente mas tarde", "", "error")
            console.log("error", error)
          }
        }}
        validate={values => {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
          const errors = {}
          if (!values.name) {
            errors.name = "Nombre Obligatorio"
          }
          if (!values.email || !emailRegex.test(values.email)) {
            errors.email = "Correo Electronico Invalido"
          }
          if (!values.message) {
            errors.message = "Mensaje Obligatorio"
          }
          return errors
        }}
      >
        {({ errors, touched }) => (
          <Fade left cascade>
            <Form>
              <label htmlFor="name">Nombre </label>
              <Field name="name" />
              <p className={styles.errorMessage}>
                {errors && errors.name ? errors.name : ""}
              </p>

              <label htmlFor="email">Correo Electronico </label>
              <Field name="email" />
              <p className={styles.errorMessage}>
                {errors && errors.email ? errors.email : ""}
              </p>

              <label htmlFor="message">Mensaje </label>
              <Field name="message" component="textarea" />
              <p className={styles.errorMessage}>
                {errors && errors.message ? errors.message : ""}
              </p>

              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={RECAPTCHA_KEY}
              />

              <button type="submit">
                {loading ? (
                  <Spinner animation="grow" role="status" variant="light" />
                ) : (
                  "Enviar"
                )}
              </button>
            </Form>
          </Fade>
        )}
      </Formik>
    </Container>
  )
}
