import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import * as styles from "../styles/projects.module.scss"
import TitlesSection from "./TitlesSection"
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { Fade, Zoom } from "react-reveal"
import slider1 from '../images/works/slider/1.jpg'
import slider3 from '../images/works/slider/3.jpg'
import slider2 from '../images/works/slider/2.jpg'

export default function Projects() {
  const data = useStaticQuery(graphql`
    query Query2 {
      allMarkdownRemark(sort: { fields: frontmatter___order, order: ASC }) {
        nodes {
          frontmatter {
            slug
            haveSlider
            featuredImg {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 50
                  transformOptions: { fit: COVER }
                )
              }
            }
          }
        }
      }
    }
  `)

  console.log(data);

  return (
    <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }} id="works">
      <Zoom>
        <TitlesSection title="trabajos" />
      </Zoom>
      <Container>
        <div className={styles.containerProjects}>
          {data.allMarkdownRemark.nodes.map(project => (
            <Link
              to={`/works/${project.frontmatter.slug}`}
              key={`${project.frontmatter.slug}-${project.id}`}
            >
              <Fade bottom>
                <div className={styles.containerFilm}>
                  {
                    !project.frontmatter.haveSlider ? (
                      <GatsbyImage
                        style={{ width: "100%", height: "100%" }}
                        image={
                          project.frontmatter.featuredImg.childImageSharp
                            .gatsbyImageData
                        }
                        alt={`imagen project.frontmatter.slug`}
                      />
                    ) : <div className={styles.slider}>
                          <figure>
                              <img src={slider1} />
                              <img src={slider2} />
                              <img src={slider3} />
                          </figure>
                        </div>
                  }
                  <div className={styles.overlayFilm}>
                    <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                  </div>
                </div>
              </Fade>
            </Link>
          ))}
        </div>
      </Container>
    </Container>
  )
}
